import { genQueries, queryOptions } from 'src/react-query';
import { fetcher } from 'fetcher!sofe';

export const integrationsQueries = genQueries(
  'integrationsQueries',
  ({ genKey }: { genKey: (key: string, params: object) => string }) => ({
    accountingIntegrations: ({ enabled }: { enabled: boolean }) =>
      queryOptions({
        queryKey: genKey('accountingIntegrations', {}),
        queryFn: () => fetcher('/wg/accounting-integrations').then((res: any) => res.integrations),
        staleTime: 1000 * 60 * 5,
        enabled,
      }),
  })
);
